<div class="region-detail scroll">
    <div class="back-btn" (click)="backToPoints()">
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.71599 8.00027L8.6705 2.12309C9.10983 1.68946 9.10983 0.986399 8.6705 0.552765C8.23116 0.119131 7.51884 0.119131 7.0795 0.552765L0.329505 7.2151C-0.109835 7.64874 -0.109835 8.3518 0.329505 8.78543L7.0795 15.4478C7.51884 15.8814 8.23116 15.8814 8.6705 15.4478C9.10983 15.0141 9.10983 14.3111 8.6705 13.8774L2.71599 8.00027Z" fill="#6097F3"/>
        </svg>
        <span>{{ textImpact.region.backTitle }}</span>
    </div>
    <ng-container *ngIf="region">
        <div class="region">
            <div class="region__title">{{ region.name }}</div>
            <div class="region__subtitle">{{ textImpact.region.subtitle }}</div>
            <div class="counters">
                <div class="counter">
                    <div class="name">Контрольные точки:</div>
                    <div class="value">0</div>
                </div>
                <div class="sep"></div>
                <div class="counter">
                    <div class="name">Посты мониторинга:</div>
                    <div class="value">{{region.post_ids?.length}}</div>
                </div>
                <div class="sep"></div>
                <div class="counter">
                    <div class="name">Плотность сети:</div>
                    <div class="value">10%</div>
                </div>
            </div> 
            <div class="content">
                <div class="content__title">{{ textImpact.region.statisticTitle }}</div>
                <div class="filters">
                    <div class="calendar-filter"
                        (clickOutside)="calendarIsOpened = false"
                    >
                        <div (click)="toggleCalendar()">
                            <input-for-dropdown
                                [label]="textImpact.sources.filters.period"
                                [inputValue]="dateRange | dateRangeText"
                                [(isOpenDropdown)]="calendarIsOpened"
                            ></input-for-dropdown>
                        </div>
                        <div class="plumes_calc__calendar">
                            <calendar-months
                                [show]="calendarIsOpened"
                                [timeBegin]="dateTimeBegin"
                                [timeEnd]="dateTimeEnd"
                                [applyCb]="updateDateRange"
                                [monthsVisible]="1"
                                [updateDays]="updateDays"
                                [columns]="1"
                                [numberOfMonths]="12"
                            ></calendar-months>
                        </div>
                    </div>
                    <div class="mmt-filter">
                        <input-dropdown
                            [inputText]="selectedSourceMmt ? selectedSourceMmt?.label : 'Не выбрано'"
                            [label]="textImpact.sources.filters.measure"
                            [(showDropdown)]="isShowDropdownForMmtList"
                            (clickOutside)="isShowDropdownForMmtList = false"
                        >
                            <selectbox-radio
                                [listItems]="mmtList"
                                (listItemsChange)="selectSourceMmt($event); isShowDropdownForMmtList = false"
                            ></selectbox-radio>
                        </input-dropdown>
                    </div>
                </div>
                <div class="widgets">
                    <div class="section-widget">
                        <div class="section-widget__title">Влияние источников на контрольную точку по <span [innerHTML]="selectedSourceMmt.label"></span> за {{ dateRange | dateRangeText }}</div>
                        <city-district-chart
                            class="widget-chart"
                            [data]="impactSourceChartData"
                            [labels]="impactSourceChartLabels"
                            [colors]="impactSourceChartColors"
                        ></city-district-chart>
                    </div>
                    <div class="section-widget">
                        <div class="section-widget__title">Максимальная расчетная разовая концентрация <span [innerHTML]="selectedSourceMmt.label"></span> за {{ dateRange | dateRangeText }}</div>
                    </div>
                    <div class="section-widget">
                        <div class="section-widget__title">Средняя расчетная концентрация загрязняющих веществ за {{ dateRange | dateRangeText }}</div>
                        <city-district-chart
                            class="widget-chart"
                            [data]="averageConcentrationChartData"
                            [labels]="averageConcentrationChartLabels"
                            [colors]="averageConcentrationChartColors"
                            [measure]="textsScheme[store.select(selectMeasureScheme) | async][
                            currentMeasure]"
                        ></city-district-chart>
                    </div>
                    <div class="section-widget">
                        <div class="section-widget__title">
                            Количество превышений расчетных концентраций выше порогового (1 ПДКмр) за {{ dateRange | dateRangeText }}
                        </div>
                        <city-district-chart
                            class="widget-chart"
                            [data]="numberExceedancesChartData"
                            [labels]="numberExceedancesChartLabels"
                            [colors]="numberExceedancesChartColors"
                            [measure]="''"
                        ></city-district-chart>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
