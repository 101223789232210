<ng-container *ngIf="store.select(selectRegions) | async as regions">
    <ng-container *ngIf="regions?.length === 0 else contentRegion">
        <non-data-screen
            class="screen-placeholders"
            [text]="textsPlumes.noRegions"
        ></non-data-screen>
    </ng-container>
    <ng-template #contentRegion>
        <div class="impact_control_points__header">
            <shared-sort-list-item
                class="region-column__name"
                (click)="setSortingCb('name')"
                [sortingDirection]="sortField === 'name' ? sortDirection : 0"
                [text]="textImpact.regions.fields.name"
            ></shared-sort-list-item>
            <shared-sort-list-item
                *ngIf="false"
                class="region-column__value"
                (click)="setSortingCb('value')"
                [sortingDirection]="sortField === 'value' ? sortDirection : 0"
                [text]="textNames[currentMeasure]"
            ></shared-sort-list-item>
        </div>
        <div class="impact_control_points__list table-data scroll">
            <div class="wrapper" *ngFor="let region of regions | orderBy : sortField : sortDirection" >
                <div class="region-item">
                    <div class="region__header">
                        <div class="region-row">
                            <div
                                class="region-column__name region-column__name_value"
                                (click)="onOpenRegion(region)"
                            >{{region.name}}</div>
                            @if(false) {
                                <div class="region-column__value region-column__value_value">
                                    <div class="color-block" *ngIf="getColorFromZone(zone, getRegionMmtValue(region.id)) as color"
                                        [ngStyle]="{'backgroundColor': color}"
                                    ></div>
                                    <div class="value">{{ getRegionMmtValue(region.id) }}</div>
                                    <div class="units" [innerHTML]="textsScheme[store.select(selectMeasureScheme) | async][
                                        currentMeasure
                                    ]"></div>
                                </div>
                            }
                            @if(false) {
                                <div class="region-column__value region-column__value_value region-column__value_value-empty">
                                    <div class="color-block"
                                         [ngStyle]="{'backgroundColor': '#b8bfcc' }"
                                    ></div>
                                    <div class="value">
                                       -
                                    </div>
                                    <div class="unit" [innerHtml]="textsScheme[store.select(selectMeasureScheme) | async][
                                        currentMeasure
                                    ]"></div>
                                </div>
                            }
                            <div
                                [ngClass]="{
                                    'region-column__arrow': true,
                                    'region-column__arrow_disabled': !region.post_ids.length
                                }"
                                (click)="onShowRegionPosts(region)"
                            >
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 0.999999L7 7L13 1" stroke="#95A0B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                        <div class="count-region">
                            <div *ngIf="false" class="row-count">
                                Контрольные точки: 1
                            </div>
                            <div *ngIf="!isRegionSelected(region)" class="row-count">
                                Посты мониторинга: {{region.post_ids?.length}}</div>
                        </div>
                    </div>
                    <div class="items-list" *ngIf="showSelectedRegionPosts && isRegionSelected(region)">
                        <div class="row-count">
                            Посты мониторинга: {{region.post_ids?.length}}
                        </div>
                        <div class="item-blocks">
                            <div class="post-row" *ngFor="let post of region.post_ids">
                                <ng-container *ngIf="store.select(getStationImpact(post)) | async as station">
                                    <div class="name">{{station.name}}</div>
                                    <div class="value-block" *ngIf="store.select(getStationImpactValue(station?.id)) | async as value else emptyVal">
                                        <div class="color-block" *ngIf="getColorFromZone(zone, value) as color"
                                            [ngStyle]="{'backgroundColor': color}"
                                        ></div>
                                        <div class="value">{{value | valueFormatNumber : digitsAfterDot}}</div>
                                        <div class="unit" [innerHtml]="textsScheme[store.select(selectMeasureScheme) | async][
                                        currentMeasure
                                    ]"></div>
                                    </div>
                                    <ng-template #emptyVal>
                                        <div class="value-block">
                                            <div class="color-block"
                                                 [ngStyle]="{'backgroundColor': '#b8bfcc' }"
                                            ></div>
                                            <div class="value">
                                               -
                                            </div>
                                            <div class="unit" [innerHtml]="textsScheme[store.select(selectMeasureScheme) | async][
                                        currentMeasure
                                    ]"></div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
