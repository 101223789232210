<div class="impact-cities scroll">
    <ng-container *ngIf="store.select(selectCurrentCityData) | async as cityData">
        <div class="city__header">
            <div class="city__header-content">
                <div class="city__title">{{ (paramsForLocality$ | async)?.cityName }}</div>
                <div class="city__time">
                    {{ TEXTS.IMPACT.cities.currentDate }}
                    <span class="city__time_bold">{{ currentDate() }}</span>
                </div>
            </div>
            <value-circle
                class="city__result"
                [value]="cityData | dataForLocalityWidget: 'instant'"
                [color]="measureZones.getColor(cityData | dataForLocalityWidget: 'instant', AQI)"
            ></value-circle>
        </div>
        <div class="city__mmts">
            <mmt-item
                *ngFor="let mmt of (paramsForLocality$ | async)?.cityMmts"
                [id]="mmt.id"
                [title]="TEXTS.NAMES[mmt.id]"
                [value]="mmt.id === 'AQI' ? (cityData | dataForLocalityWidget: 'instant') : (cityData | dataForLocalityWidget : mmt.id)"
                [unit]="mmt.id === 'AQI' ? getAQIunit(cityData | dataForLocalityWidget: 'instant') : TEXTS.MEASURES_MG[mmt.id]"
            ></mmt-item>
        </div>
    </ng-container>
    <div class="city__image"></div>
    <div class="city__widgets">
        <widget 
            class="city__widget widget__env-chars"
            [title]="TEXTS.IMPACT.cityEnvChars.title"
        >
            <div class="env-chars__items">
                <div class="env-chars__item">
                    <div class="env-chars__item-label">Население</div>
                    <div class="env-chars__item-value">~ 1,6 млн. жителей</div>
                </div>
                <div class="env-chars__item">
                    <div class="env-chars__item-label">Площадь территории</div>
                    <div class="env-chars__item-value">502.7 км²</div>
                </div>
                <!-- @if(cityData?.population; as population) {
                    <div class="env-chars__item">
                        <div class="env-chars__item-label">Население</div>
                        <div class="env-chars__item-value">~ {{population}} млн. жителей</div>
                    </div>
                } -->
                <!-- @else {
                    <div class="env-chars__item">
                        <div class="env-chars__item-label">Население</div>
                        <div class="env-chars__item-value">~ 1,6 млн. жителей</div>
                    </div>
                } -->
                <!-- @if(cityData?.city_square; as citySquare) {
                <div class="env-chars__item">
                    <div class="env-chars__item-label">Площадь территории</div>
                    <div class="env-chars__item-value">{{citySquare}} км²</div>
                </div>
                } -->
                <!-- @else {
                    <div class="env-chars__item">
                        <div class="env-chars__item-label">Площадь территории</div>
                        <div class="env-chars__item-value">502.7 км²</div>
                    </div>
                } -->
            </div>
        </widget>
        <widget 
            class="city__widget widget__network-density"
            [title]="TEXTS.IMPACT.networkDensity.title"
            [widgetLink]="widgetLink"
        >
            <ng-template
                #widgetLink
            >
                <a class="widget-link" href="#">{{ TEXTS.IMPACT.networkDensity.linkMoreText }}</a>
            </ng-template>
            <div *ngIf="networkDensityData$ | async as networkDensity" class="network-density__common-data">
                <div class="network-density__summary">
                    <div class="network-density__summary-result">{{ getStatusByData(networkDensity) }}</div>
                    <div class="network-density__summary-detail">
                        {{postCountText(networkDensity.stations_count)}} {{TEXTS.IMPACT.networkDensity.monitoring}} <span [innerHtml]="TEXTS.IMPACT.networkDensity.squareText(formatSquare(networkDensity.network_area_total))"></span>
                    </div>
                </div>
                <div class="network-density__average-percent">
                    <span>{{ networkDensity.stations_coverage * 100 }}</span>%
                </div>
            </div>
            <div class="network-density__region-table">
                <div class="network-density__region-table_header">
                    <shared-sort-list-item
                        class="region-column__title"
                        (click)="setRegionSortingCb('name')"
                        [sortingDirection]="sortRegionField === 'name' ? sortRegionDirection : 0"
                        [text]="TEXTS.IMPACT.networkDensity.headers.name"
                    ></shared-sort-list-item>
                    <shared-sort-list-item
                        class="region-column__posts"
                        (click)="setRegionSortingCb('posts')"
                        [sortingDirection]="sortRegionField === 'posts' ? sortRegionDirection : 0"
                        [text]="TEXTS.IMPACT.networkDensity.headers.posts"
                    ></shared-sort-list-item>
                    <shared-sort-list-item
                        class="region-column__area"
                        (click)="setRegionSortingCb('area')"
                        [sortingDirection]="sortRegionField === 'area' ? sortRegionDirection : 0"
                        [text]="TEXTS.IMPACT.networkDensity.headers.area"
                    ></shared-sort-list-item>
                    <shared-sort-list-item
                        class="region-column__density"
                        (click)="setRegionSortingCb('density')"
                        [sortingDirection]="sortRegionField === 'density' ? sortRegionDirection : 0"
                        [text]="TEXTS.IMPACT.networkDensity.headers.density"
                    ></shared-sort-list-item>
                </div>
                <div class="network-density__region-table_body">
                    <ng-container *ngFor="let region of regions$ | async | orderBy : sortRegionField : sortRegionDirection; index as i">
                        <div 
                            *ngIf="i < 3 || showAllRegions"  
                            class="network-density__region-item"
                        >
                            <div class="region-column__title region-column__title-value">{{ region.name }}</div>
                            <div class="region-column__posts region-column__posts-value">{{ region.posts.length }}</div>
                            <div class="region-column__area region-column__area-value">{{ region.area }}</div>
                            <div class="region-column__density region-column__density-value">{{ region.density }}</div>
                        </div>
                    </ng-container>
                </div>
                <div
                    *ngIf="!showAllRegions" 
                    class="show-more" 
                    (click)="showAllRegions = !showAllRegions"
                >
                    <span>Смотреть все</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M1 1L5 5L9 1" stroke="#95A0B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </widget>
        <widget 
            class="city__widget widget__emission-sources"
            [title]="TEXTS.IMPACT.emissionSources.title"
            [widgetLink]="sourceLink"
        >
            <ng-template
                #sourceLink
            >
                <div 
                    class="widget-link"
                    (click)="goToSource()"
                >{{ TEXTS.IMPACT.emissionSources.link }}</div>
            </ng-template>
            <div class="emission-total">
                <div class="emission-total__item">
                    <div class="label">Всего</div>
                    <div class="emission-total__item-value">8</div>
                </div>
                <div class="emission-total__item">
                    <div class="label">Объём выбросов</div>
                    <div class="emission-total__item-value">~ 500 т/г</div>
                </div>
            </div>
            <div class="emission-spices">
                <div class="label">Вещества</div>
                <div class="emission-spices__list">
                    <div 
                        *ngFor="let mmt of sourceMmtList" 
                        class="mmt"
                        [innerHTML]="mmt.name"
                    ></div>
                </div>
                <div *ngIf="showAllMmt"  class="emission-spices__details">
                    <div *ngFor="let mmt of sourceMmtList" class="detail">
                        <div class="name" [innerHTML]="mmt.name"></div>
                        <div class="value">
                            <span class="number">{{ mmt.value }}</span>
                            <span class="unit">т/г</span>
                        </div>
                        <div class="percent">{{ getEmissionPercent(mmt.value) }}</div>
                    </div>
                </div>
                <div 
                    class="show-more" 
                    (click)="showAllMmt = !showAllMmt"
                >
                    @if(!showAllMmt){
                        <span>Смотреть по веществам</span>
                    }
                    @else {
                        <span>Скрыть</span>
                    }
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M1 1L5 5L9 1" stroke="#95A0B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </widget>
        <widget 
            class="city__widget widget__city-districts"
            [title]="TEXTS.IMPACT.cityDistricts.title"
            [widgetLink]="cityDistrictsLink"
        >
            <ng-template
                #cityDistrictsLink
            >
                <div 
                    class="widget-link"
                    (click)="goToControlPoints()"
                >
                    {{ TEXTS.IMPACT.cityDistricts.link }}
                </div>
            </ng-template>
            <div class="districts__subtitle">Топ-5 по сумме вклада источников</div>
            <city-district-chart
                [data]="dataChart"
                [labels]="labelChart"
                [colors]="colorsChat"
            ></city-district-chart>
        </widget>
        <widget 
            class="city__widget widget__air-quality-city"
            [title]="TEXTS.IMPACT.airQualityCity.title"
        >
            <div class="air-quality__charts">
                <shared-ui-analytic
                    class="charts-wrapper"
                    [selectedCity]="currentCity"
                    [isLoading]="isLoadingSummary$ | async"
                    [distributionSummary]="distributionSummary$ | async"
                    [isDemo]="isDemo$ | async"
                    [showCitySelector]="false"
                ></shared-ui-analytic>
            </div>
        </widget>
    </div>
</div>
