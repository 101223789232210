import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { selectSpeciesList } from '@cityair/modules/forecast/store/selectors';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { SourceImpact } from '@cityair/modules/impact/service/api-model-impact';
import { ImpactActions, impactFeature, selectActiveSources, selectSourceListDemoImpact } from '@cityair/modules/impact/store/impact.feature';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { TEXTS } from '@libs/common/texts/texts';
import { Store } from '@ngrx/store';

@Component({
    selector: 'ca-impact-sources',
    templateUrl: 'impact-sources.component.html',
    styleUrls: ['impact-sources.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactSourcesComponent {
    sources$ = this.store.select(selectSourceListDemoImpact);
    species$ = this.store.select(selectSpeciesList);
    textImpact = TEXTS.IMPACT;

    readonly mainComponentUrl = `/${MAIN_PAGES.impact}/`;

    constructor(
        public store: Store,
        private router: Router,
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.sources }));
        this.store.select(selectActiveSources).subscribe({
            next: (v) => {
                console.log(v);
            }
        })
    }

    goToSource(source: SourceImpact){
        this.router.navigate([`${this.mainComponentUrl}${IMPACT_PAGES.sources}/${source.id}`]);
    }
}
