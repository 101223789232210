import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { selectMeasureScheme } from '@cityair/modules/core/store/selectors';
import {
    AVERAGE_CONCENTRATION_FOR_REGION,
    IMPACT_SOURCE_ON_REGION,
    NUMBER_EXCEEDANCES_FOR_REGION,
} from '@cityair/modules/impact/consts';
import { DateRangeImpact } from '@cityair/modules/impact/service/api-model-impact';
import {
    ImpactActions,
    impactFeature,
    selectActiveMmt,
    selectRegions,
} from '@cityair/modules/impact/store/impact.feature';
import { TEXTS } from '@libs/common/texts/texts';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import { Store } from '@ngrx/store';
import moment from 'moment-timezone';
import { Subject, takeUntil } from 'rxjs';
import { RegionApi } from '@libs/common/models/basicModels';
import { ActivatedRoute, Router } from '@angular/router';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'impact-region-detail',
    templateUrl: './impact-region-detail.component.html',
    styleUrls: ['./impact-region-detail.component.less'],
})
export class ImpactRegionDetailComponent implements OnInit, OnDestroy {
    public textImpact = TEXTS.IMPACT;
    public textNames = TEXTS.NAMES;
    public textsScheme = TEXTS.MEASURES_SCHEME;
    public currentMeasure: string;
    public selectActiveMmt = selectActiveMmt;
    public calendarIsOpened = false;
    public region: RegionApi;
    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;
    public numberOfFutureDays = 1;
    public dateRange: DateRangeImpact;
    public dateTimeBegin: number;
    public dateTimeEnd: number;
    public ngDestroyed$ = new Subject<void>();
    public mmtList: CheckboxItem[] = [];
    public selectedSourceMmt: CheckboxItem;
    public isShowDropdownForMmtList: boolean = false;
    selectMeasureScheme = selectMeasureScheme;
    NUMBER_EXCEEDANCES_FOR_REGION = NUMBER_EXCEEDANCES_FOR_REGION;
    numberExceedancesChartData: number[] = [];
    numberExceedancesChartLabels: string[] = [];
    numberExceedancesChartColors: string[] = [];
    AVERAGE_CONCENTRATION_FOR_REGION = AVERAGE_CONCENTRATION_FOR_REGION;
    averageConcentrationChartData: number[] = [];
    averageConcentrationChartLabels: string[] = [];
    averageConcentrationChartColors: string[] = [];
    IMPACT_SOURCE_ON_REGION = IMPACT_SOURCE_ON_REGION;
    impactSourceChartData: number[] = [];
    impactSourceChartLabels: string[] = [];
    impactSourceChartColors: string[] = [];
    loading = true;
    currentId: string;
    constructor(
        public store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.currentId = this.route.snapshot.paramMap.get('id');
        if (this.currentId) {
            this.store
                .select(selectRegions)
                .pipe(
                    takeUntil(this.ngDestroyed$),
                    filter((data) => data?.length > 0),
                    take(1)
                )
                .subscribe((data) => {
                    this.region = data?.find((v) => v.id === this.currentId);
                    if (this.region) {
                        this.loading = false;
                    } else {
                        this.backToPoints();
                    }
                    _changeDetectorRef.markForCheck();
                });
        } else {
            this.backToPoints();
        }
        this.store
            .select(selectActiveMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => (this.currentMeasure = data));
        this.store
            .select(impactFeature.selectDateRangeRegion)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((dates) => {
                this.dateRange = dates;
                this.dateTimeBegin = moment(this.dateRange.start).valueOf();
                this.dateTimeEnd = moment(this.dateRange.end).valueOf();
            });
        this.NUMBER_EXCEEDANCES_FOR_REGION.map((v) => {
            this.numberExceedancesChartData = [...this.numberExceedancesChartData, v.value];
            this.numberExceedancesChartLabels = [...this.numberExceedancesChartLabels, v.name];
            this.numberExceedancesChartColors = [...this.numberExceedancesChartColors, v.color];
        });
        this.AVERAGE_CONCENTRATION_FOR_REGION.map((v) => {
            this.averageConcentrationChartData = [...this.averageConcentrationChartData, v.value];
            this.averageConcentrationChartLabels = [
                ...this.averageConcentrationChartLabels,
                v.name,
            ];
            this.averageConcentrationChartColors = [
                ...this.averageConcentrationChartColors,
                v.color,
            ];
        });
        this.IMPACT_SOURCE_ON_REGION.map((v) => {
            this.impactSourceChartData = [...this.impactSourceChartData, v.value];
            this.impactSourceChartLabels = [...this.impactSourceChartLabels, v.name];
            this.impactSourceChartColors = [...this.impactSourceChartColors, v.color];
        });
    }

    ngOnInit(): void {
        this.store
            .select(impactFeature.selectSpeciesList)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((list) => {
                list.map((item, index) =>
                    this.mmtList.push({
                        id: item,
                        label: this.textNames[item],
                        selected: index === 0 ? true : false,
                    })
                );
                this.selectedSourceMmt = this.mmtList[0];
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
    }

    toggleCalendar() {
        if (this.calendarIsOpened) {
            this.handleUpdateDays(this.numberOfFutureDays);
        }
    }

    public updateDateRange = (begin: number, finish: number) => {
        this.calendarIsOpened = false;
        const start = moment(begin).startOf('day').toISOString();
        const end = moment(finish).endOf('day').toISOString();
        this.store.dispatch(ImpactActions.updateDateRangeRegion({ payload: { start, end } }));
    };

    updateDays = (fn: (numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);

    selectSourceMmt(list: CheckboxItem[]) {
        const selectedMmt = this.mmtList.find(
            (dl) => dl.id === list.find((s) => s.selected == true).id
        );
        this.selectedSourceMmt = selectedMmt;
    }

    backToPoints() {
        this.router.navigate([
            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.regions}`,
        ]);
    }
}
