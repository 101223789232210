import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SOURCES_TYPE_BY_ID } from '@libs/common/consts/demo-impact-groups';
import { formatNumber } from '@angular/common';
import { customNumberFormat, isFalseNumber } from '@libs/common/utils/utils';
import { LANGUAGE } from '@libs/common/texts/texts';
import { ValueFormatNumberPipe } from '@libs/shared-ui/components/pipes/pipes';

@Component({
    standalone: true,
    selector: 'impact-region-pin',
    templateUrl: './impact-region-pin.component.html',
    styleUrls: ['./impact-region-pin.component.less'],
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ImpactRegionPinComponent implements OnInit, OnChanges {
    isFalseNumber = isFalseNumber;

    @Input() value: number;
    @Input() numberAfterDot?: number = 0;
    @Input() color: string;
    @Input() selected: boolean;
    @Input() name: string;

    displayValue: number | string;
    displayName: string;
    hasNoData: boolean;

    ngOnInit(): void {
        this.displayName = this.name?.replace(" район", "");
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            const { value, numberAfterDot } = this;
            const hasNoData = isFalseNumber(value);
            const formatValue = formatNumber(
                value,
                LANGUAGE,
                '1.' + numberAfterDot + '-' + numberAfterDot
            );
            this.displayValue = hasNoData
                ? '–'
                : customNumberFormat(formatValue, numberAfterDot, LANGUAGE);
        }
    }

    
}
