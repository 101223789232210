import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Store } from '@ngrx/store';
import { ChartConfiguration, LinearScaleOptions, Scale } from 'chart.js';
import { draw } from 'patternomaly';
import { BaseChartDirective } from 'ng2-charts';
import * as moment from 'moment-timezone';

import { MeasureScheme } from '@libs/common/enums/measure-scheme';
import { AQI_WITH_DETAILS } from '@libs/common/consts/aqi-with-details.const';
import { isRU, TEXTS } from '@libs/common/texts/texts';
import { TimelineState } from '../store/index';
import { selectMainChartIsLoading, selectChartEnabled } from '../store/selectors/core.selectors';
import { Feature } from '@libs/common/models/feature';
import { CHART_TOOLTIP_MAX_STRINGS, MEASUREMENTS_CONFIG } from './utils/config';
import {
    LINE_DASH_STYLES,
    METEO_VALUES_COLORS,
    TICK_COLORS,
    TICK_LABEL_COLORS,
} from '../constants';
import { TicksHelper } from './utils/ticks-helper';
import {
    createContributionTr,
    createElement,
    createTooltipIzaTr,
    createTooltipTr,
    getOrCreateTooltip,
    labelToKey,
} from '../chart-timeline/utils/tooltip-helper';
import { measureZones } from '@libs/common/helpers/measure-zones';
import { AQI } from '@libs/common/consts/substance.consts';
import { AqiType } from '@libs/common/enums/aqi.type';
import { GroupChartConfig } from '@libs/common/models/group-chart-config.model';
import { isFalseNumber } from '@libs/common/utils/utils';
import { NO_DATA_COLOR } from '@libs/common/consts/no-data-color.const';
import { GASES } from '@libs/common/consts/mmt-with-pdk-sorted.conts';
import { DataQualityTimeline } from '@libs/common/models/dataQuality';
import { formatDayMonth } from '@libs/common/utils/date-formats';
import { TIME_FORMAT } from '@libs/common/consts/date-format.const';
import { getIntervalInHours } from '@libs/shared-ui/components/timeline-panel/utils';

export const ANIMATION_CHART_HEIGHT = [
    trigger('inOutAnimation', [
        transition(':leave', [
            style({ height: 180 }),
            animate('0.2s ease-in', style({ height: 0 })),
        ]),
    ]),
];
export const Y_SCALE_CONTRIBUTIONS_ID = 'bar-y-axis';

@Component({
    selector: 'ca-chart-timeline',
    templateUrl: './chart-timeline.component.html',
    styleUrls: ['./chart-timeline.component.less'],
    animations: [ANIMATION_CHART_HEIGHT],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTimelineComponent implements OnChanges {
    @Input() timeIndex: number;
    @Input() data: Feature[];
    @Input() showGridLines?: boolean;
    @Input() mmts?: string[];
    @Input() chartMinMax: GroupChartConfig;
    @Input() measureScheme: MeasureScheme;
    @Input() hasDataByIndex: boolean[] = [];
    @Input() qualityDataMode: number;
    @Input() dataQuality: DataQualityTimeline[] = [];

    @Output() setPosition = new EventEmitter<number>();
    @Output() sizeUpdate = new EventEmitter<{ left: number; width: number }>();

    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    noDataForMmtText = TEXTS.STATION_BLOCK.noDataForMmt;
    noDataText = TEXTS.STATION_BLOCK.noData;
    public chartEnabled: boolean;
    public cityNameField: string;
    private hiddenTimeInTooltip = false;
    chartLoading$ = this.store.select(selectMainChartIsLoading);

    measureZones = measureZones;
    AQI = AQI;
    units: Record<string, string>;
    dashLinesMap: {
        [key: string]: number;
    } = {};

    hoverLinePosition = 0;
    hoverLineVisible = false;

    constructor(
        private store: Store<TimelineState>,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.initState();
        this.cityNameField = isRU ? 'name_ru' : 'name';
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.data) {
            this.data = [];
        }

        if (changes.data && this.data) {
            this.hiddenTimeInTooltip = this.hiddenTime();
            this.dashLinesMap = this.data.reduce(
                (acc, v, i) => ({
                    ...acc,
                    [v.properties.uuid]: LINE_DASH_STYLES[i]?.join(' '),
                }),
                {}
            );
            this.updateChart();
        }

        if (changes.mmts?.currentValue) {
            this.updateChart();
        }
    }

    chartClick({ event }: any) {
        const activeElements = this.chart.chart.getElementsAtEventForMode(
            event,
            'index',
            { intersect: false },
            false
        );
        const element = activeElements[0];

        if (element) {
            const len = this.data[0]?.properties?.timeseries?.date?.length;
            if (element.index > 0 && element.index < len + 2) {
                this.setPosition.emit(element.index - 1);
            }
        }
    }

    private externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;

        // do not show empty tooltips
        if (!tooltip.dataPoints || tooltip.dataPoints.length === 0) {
            return;
        }

        const tooltipEl = getOrCreateTooltip(chart);
        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
        // Set Text
        if (tooltip.body) {
            const currentTime = tooltip.dataPoints[0]?.raw?.x;

            const data = formatDayMonth(moment(currentTime));
            const format = this.hiddenTimeInTooltip
                ? data
                : `${data} ${moment(currentTime).format(`${TIME_FORMAT}`)}`;
            const titleLines = [format];
            const bodyLines = tooltip.body.map((b) => b.lines);
            document.body.querySelector('.chart_tooltip__title')?.remove();
            const tableHead = createElement('div', 'chart_tooltip__title');

            titleLines.forEach((title) => {
                const div = createElement('div');

                div.appendChild(document.createTextNode(title));
                tableHead.appendChild(div);
            });

            const tableBody = document.createElement('tbody');
            const allValues: string[] = [];
            if (this.data[0].properties?.contributions) {
                let lineValue = null;
                const contributionDataTooltip = [];
                bodyLines.forEach((body, i) => {
                    const value = tooltip.dataPoints[i].parsed.y;
                    if (tooltip.dataPoints[i].dataset?.type === 'line') {
                        lineValue = value;
                        const key = labelToKey(tooltip.dataPoints[i].dataset?.label);
                        let color = measureZones.getColor(value, key);

                        if (
                            GASES.includes(key) ||
                            (color === NO_DATA_COLOR && METEO_VALUES_COLORS[key])
                        )
                            color = METEO_VALUES_COLORS[key];
                        const tr = createTooltipTr(color, body, value);
                        tableBody.appendChild(tr);
                    } else if (tooltip.dataPoints[i].dataset?.type === 'bar' && Math.round(value)) {
                        const label = tooltip.dataPoints[i].dataset?.label?.split('$$')[0];
                        contributionDataTooltip.push({
                            value: Math.round(value),
                            color: tooltip.dataPoints[i].dataset?.backgroundColor,
                            label,
                        });
                    }
                });
                if (lineValue && contributionDataTooltip.length) {
                    const tr = createElement('tr', 'chart_tooltip__contrib_tr');
                    const td1 = createElement('td', 'chart_tooltip__td1');
                    td1.innerHTML = `<b>${TEXTS.ADMIN_PANEL.impact}:</b>`;
                    tr.appendChild(td1);
                    tableBody.appendChild(tr);
                    contributionDataTooltip.sort((a, b) => {
                        if (b.value < a.value) {
                            return -1;
                        }
                        if (b.value > a.value) {
                            return 1;
                        }
                    });
                    contributionDataTooltip.forEach((v) => {
                        const trI = createContributionTr(v);
                        tableBody.appendChild(trI);
                    });
                }
            } else {
                bodyLines.length = CHART_TOOLTIP_MAX_STRINGS;
                bodyLines.forEach((body, i) => {
                    const value = tooltip.dataPoints[i].parsed.y;
                    const key = labelToKey(tooltip.dataPoints[i].dataset?.label);
                    allValues.push(key);
                    let color = measureZones.getColor(value, key);

                    if (
                        GASES.includes(key) ||
                        (color === NO_DATA_COLOR && METEO_VALUES_COLORS[key])
                    )
                        color = METEO_VALUES_COLORS[key];

                    const tr = createTooltipTr(color, body, value);
                    tableBody.appendChild(tr);
                });
            }

            if (allValues.length === 1 && AQI_WITH_DETAILS.includes(allValues[0] as AqiType)) {
                const timestamp = tooltip.dataPoints[0].raw?.x;
                const currentAQIIndex = allValues[0];
                const tooltipTimeIndex =
                    this.data[0].properties?.timeseries?.date?.indexOf(timestamp);
                if (
                    this.data.length &&
                    this.data[0].properties?.details.hasOwnProperty(currentAQIIndex) &&
                    this.data[0].properties?.details[currentAQIIndex] &&
                    this.data[0].properties?.details[currentAQIIndex].length &&
                    this.data[0].properties?.timeseries[currentAQIIndex]
                ) {
                    const val =
                        this.data[0].properties?.timeseries[currentAQIIndex][tooltipTimeIndex];
                    const details =
                        this.data[0].properties?.details[currentAQIIndex][tooltipTimeIndex];
                    if (details && val) {
                        createTooltipIzaTr({ val, details }, tableBody);
                    }
                }
            }

            const tableRoot = tooltipEl.querySelector('table');
            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.before(tableHead);
            tableRoot.appendChild(tableBody);
            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            if (tooltip.caretX < chart.width / 2) {
                tooltipEl.style.left = positionX + tooltip.caretX + tooltip.width / 2 + 25 + 'px';
            } else {
                tooltipEl.style.left = positionX + tooltip.caretX - tooltip.width / 2 - 25 + 'px';
            }
            tooltipEl.style.top = positionY - 20 + 'px';
            tooltipEl.style.width = 'max-content';
            tooltipEl.style.whiteSpace = 'nowrap';
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
                tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        }
    };

    chartPlugins: ChartConfiguration['plugins'] = [
        {
            id: 'adjustScales',
            afterUpdate: (chart) => {
                const xScale = chart.scales.x;
                const data = (chart.data.datasets[0]?.data as any[]) ?? [];
                const len = data?.length;
                if (len >= 25) {
                    const val1 = data[1]?.x;
                    const valN = data[len - 2]?.x;
                    const x1 = xScale.getPixelForValue(moment(val1).valueOf());
                    const xN = xScale.getPixelForValue(moment(valN).valueOf());
                    const delta = (xN - x1) / (len - 1);
                    if (delta !== 0 && x1 > 0) {
                        this.sizeUpdate.emit({
                            left: x1 - delta / 2,
                            width: delta * len,
                        });
                    }
                } else {
                    const {
                        chartArea: { left, width },
                    } = chart;
                    if (left !== null && width) {
                        this.sizeUpdate.emit({
                            left: left + 10,
                            width: width,
                        });
                    }
                }
            },
        },
        {
            id: 'adjustScalesOnDestroy',
            afterDestroy: () => {
                this.sizeUpdate.emit(null);
            },
        },
        {
            id: 'skippedArea',
            beforeDraw: (chart, args, pluginOptions) => {
                if (!this.qualityDataMode) return;
                const {
                    ctx,
                    data,
                    chartArea: { top, height },
                    scales: { x },
                } = chart;
                if (data.datasets.length) {
                    ctx.save();
                    const dataChart = (data.datasets[0]?.data as any[]) ?? [];
                    dataChart.map((datapoint, index) => {
                        if (
                            index !== 0 &&
                            index !== dataChart.length - 1 &&
                            (this.dataQuality[index - 1]?.isCritical ||
                                !this.hasDataByIndex[index - 1])
                        ) {
                            const x1 = x.getPixelForValue(moment(dataChart[index]?.x).valueOf());
                            const x2 = x.getPixelForValue(
                                moment(dataChart[index + 1]?.x).valueOf()
                            );
                            if (this.dataQuality[index - 1]?.isCritical) {
                                ctx.fillStyle = 'rgba(236, 18, 96, 0.1)';
                            } else {
                                ctx.fillStyle = draw(
                                    'diagonal-right-left',
                                    'rgba(255, 255, 255, 1)',
                                    'rgba(184, 191, 204 )',
                                    9
                                );
                            }

                            ctx.fillRect(x1 - (x2 - x1) / 2, top, x2 - x1, height);
                        }
                    });
                    ctx.restore();
                }
            },
        },
    ];

    chartOptions: ChartConfiguration['options'] = {
        responsive: true,
        resizeDelay: 100,
        maintainAspectRatio: false,
        animation: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 20,
                bottom: 10,
            },
        },
        elements: {
            line: {
                fill: false,
                tension: 0,
                stepped: false,
                borderDash: [],
                borderWidth: 1,
                borderJoinStyle: 'bevel',
            },
            point: {
                radius: 0,
                hoverRadius: 0,
                hitRadius: 10,
            },
        },
        onHover: (e, _, chart) => {
            const activeElements = chart.getElementsAtEventForMode(
                e.native,
                'index',
                { intersect: false },
                false
            );
            const element = activeElements[0];

            if (element) {
                const { datasetIndex, index } = element;
                const meta = chart.getDatasetMeta(datasetIndex);

                if (index > 0 && index < meta.data.length - 1) {
                    this.hoverLinePosition = meta.data[index].x + 10;
                    this.hoverLineVisible = true;
                    this._changeDetectorRef.detectChanges();
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                enabled: false,
                external: this.externalTooltipHandler,
                filter: ({ dataset, dataIndex }) =>
                    dataIndex > 0 && dataIndex < dataset.data.length - 1,
            },
        },
    };

    private createYAxisConfig() {
        const yScale = this.mmts.reduce((acc, mmt) => {
            const id = this.getAxisId(mmt);

            return !acc[id]
                ? {
                      ...acc,
                      [id]: {
                          id,
                          position: 'right',
                          beginAtZero: false,
                          min: MEASUREMENTS_CONFIG[mmt]?.minChartValue,
                          max: MEASUREMENTS_CONFIG[mmt]?.maxChartValue,
                          ticks: {
                              display: false,
                              rotation: 0,
                              crossAlign: 'center',
                              mirror: true,
                          },
                          border: {
                              display: false,
                          },
                          grid: {
                              color: '#e6e6e6',
                              tickLength: 0,
                              display: false,
                          },
                      },
                  }
                : acc;
        }, {} as ChartConfiguration['options']['scales']);

        if (this.data[0]?.properties?.contributions) {
            yScale[Y_SCALE_CONTRIBUTIONS_ID] = {
                id: Y_SCALE_CONTRIBUTIONS_ID,
                stacked: true,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                display: false,
                min: 0,
                max: 100,
            };
        }
        return yScale;
    }

    private updateChart() {
        this.updateUnits();
        if (this.units) {
            const chartMinMax = this.mmts.length === 1 ? this.chartMinMax : null;
            const yAxes = this.createYAxisConfig();
            this.updateYAxes(yAxes, chartMinMax);
        }
    }

    private getAxisId(mmt: string) {
        return this.units[mmt] ?? mmt;
    }

    private updateGridSettings(
        axis: ChartConfiguration['options']['scales']['prop'],
        axisSettings: GroupChartConfig['prop']
    ) {
        const { min, max, intervalMajor, intervalMinor } = axisSettings;

        if (!isNaN(min)) {
            axis.min = min;
        }

        if (!isNaN(max)) {
            axis.max = max;
        }

        const ticksHelper = new TicksHelper(intervalMajor, intervalMinor);

        if (!isNaN(intervalMajor)) {
            axis.grid.color = (context) =>
                ticksHelper.isMajor(context.tick?.value) ? TICK_COLORS.major : TICK_COLORS.minor;
            axis.ticks.color = (context) =>
                ticksHelper.isMajor(context.tick?.value)
                    ? TICK_LABEL_COLORS.major
                    : TICK_LABEL_COLORS.minor;

            (axis as LinearScaleOptions).ticks.autoSkip = false;
            (axis as LinearScaleOptions).ticks.stepSize = intervalMinor || intervalMajor;

            // ideally is: maxTicksLimit = Math.floor(height / ticks.fontSize) for the major ticks
            axis.ticks.maxTicksLimit = (max - min) / intervalMajor > 11 ? 11 : 21;
        }
    }

    private updateYAxes(
        scales: ChartConfiguration['options']['scales'],
        chartMinMax?: GroupChartConfig
    ) {
        const yAxes = Object.keys(this.createYAxisConfig()).reduce(
            (acc, k) =>
                k === 'x'
                    ? acc
                    : {
                          ...acc,
                          [k]: scales[k],
                      },
            {} as ChartConfiguration['options']['scales']
        );

        if (chartMinMax) {
            Object.values(yAxes).forEach((axis) => {
                const mmt = this.mmts.find((mmt) => (axis as Scale).id === this.getAxisId(mmt));
                const axisSettings = chartMinMax[mmt];

                if (axisSettings) {
                    this.updateGridSettings(axis, axisSettings);
                } else {
                    axis.ticks = {};
                    if (isFalseNumber(MEASUREMENTS_CONFIG[mmt]?.maxChartValue)) {
                        delete axis.max;
                    }
                    if (isFalseNumber(MEASUREMENTS_CONFIG[mmt]?.minChartValue)) {
                        delete axis.min;
                    }
                }
            });
        }

        Object.values(yAxes).forEach((axis) => {
            axis.display = false;
            axis.ticks.display = false;
            axis.grid.display = false;
        });

        if (this.selectedMeasurementsOfSameScale()) {
            const mmt = this.mmts[0];
            Object.keys(yAxes)
                .filter((id) => id === this.getAxisId(mmt))
                .forEach((id) => {
                    const axis = yAxes[id];
                    if (this.showGridLines) {
                        axis.display = true;
                        axis.ticks.display = true;
                        axis.grid.display = true;
                    }
                });
        } else {
            const axis = yAxes[this.getAxisId(AQI)];
            if (axis && this.showGridLines) {
                axis.display = true;
                axis.grid.display = true;
            }
        }

        // push updates to the chart component
        this.chartOptions = {
            ...this.chartOptions,
            scales: {
                x: this.xScale,
                ...yAxes,
            },
        };
    }

    private selectedMeasurementsOfSameScale() {
        const ids = new Set(this.mmts.map((mmt) => this.getAxisId(mmt)));
        return ids.size === 1;
    }

    private initState() {
        this.store.select(selectChartEnabled).subscribe((data) => {
            this.chartEnabled = data;
            this._changeDetectorRef.markForCheck();
        });
    }

    private updateUnits() {
        this.units = this.mmts.reduce(
            (acc, mmt) => ({
                ...acc,
                [mmt]: TEXTS.MEASURES_SCHEME[this.measureScheme][mmt],
            }),
            {}
        );
    }

    private xScale: any = {
        type: 'time',
        stacked: true,
        display: false,
        ticks: {
            display: false,
        },
        time: {
            tooltipFormat: 'DD MMM, HH:mm',
            parser: (value: string) => moment(value).valueOf(),
        },
        grid: {
            display: false,
        },
    };

    private hiddenTime() {
        if (this.data.length && this.data[0].properties.timeseries.date.length) {
            const start = this.data[0].properties.timeseries.date[0];
            const end = this.data[0].properties.timeseries.date[1];
            const hours = getIntervalInHours(start, end);
            return hours >= 24;
        }
        return false;
    }
}
