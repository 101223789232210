<div
    [ngClass]="{
        'region-pin': true,
        'region-pin_state-selected': selected
    }"
>
    <div class="region-pin__body">
        <div 
            class="region-pin__value"
            [ngStyle]="{
                'background-color': color
            }">
                {{ displayValue }}
            <div class="region-pin__line"></div>
            <div
                class="region-pin__end"
                [style]="{ backgroundColor: color }"
            ></div>
        </div>
        <div class="region-pin__name">
            {{ displayName }}
        </div>
    </div>
</div>